<template>
  <div>
    <van-form @submit="onSubmit" input-align="right" error-message-align="right" ref="form" :scroll-to-error="true">
      <div class="info">
        <van-cell class="info-title">
          <template #title>
            <div class="blue-box"></div>
            <span class="form-title-view">基本信息</span>
          </template>
          <template #default v-if="agentType == 1">
            <van-uploader :after-read="cardIdIdentified">
              <div>
                <van-icon size="25" color="#3095FF" name="photograph" />
              </div>
            </van-uploader>
          </template>
        </van-cell>
        <van-field v-if="showCommunity" name="所属社区" label="所属社区" v-model="show.community" placeholder="请选择所属社区"
          readonly clickable is-link @click="control.community = true"
          required :rules="[{ required: true, message: '请选择所属社区' }]" />
        <van-popup v-model="control.community" position="bottom">
          <van-picker show-toolbar value-key="name" :columns="selectList.communityList" @confirm="communityConfirm($event)"
            @cancel="control.community = false" />
        </van-popup>

        <van-field name="证件类型" label="证件类型" v-model="show.idNumberTypeStr" placeholder="点击选择证件类型"
          readonly clickable is-link @click="control.idNumberType = true"
          required :rules="[{ required: true, message: '请选择证件类型' }]" />
        <van-popup v-model="control.idNumberType" position="bottom">
          <van-picker show-toolbar value-key="label" :columns="selectList.idNumberTypeList" @confirm="idNumberType"
            @cancel="control.idNumberType = false" />
        </van-popup>

        <van-field name="证件号" label="证件号" v-model="dataForm.idNumber" placeholder="请输入有效证件号"
          readonly clearable clear-trigger="always" @blur="getInfoByIdNumber"
          required :rules="[{ validator: IdentityCodeValid, message: '请输入有效证件号' }]" />

        <van-field name="姓名" label="姓名" v-model="dataForm.userName" placeholder="请输入姓名或者拍照识别"
          clearable clear-trigger="always"
          required :rules="[{ required: true, message: '请输入姓名或者拍照识别' }]" />

        <van-field name="性别" label="性别" v-model="show.sex" placeholder="请选择性别"
          readonly clickable is-link @click="control.sex = true"
          required :rules="[{ required: true, message: '请选择性别' }]" />
        <van-popup v-model="control.sex" position="bottom">
          <van-picker show-toolbar value-key="label" :columns="[{ label: '男', value: 1 }, { label: '女', value: 2 }]"
            @confirm="sexConfirm" @cancel="control.sex = false" />
        </van-popup>

        <van-field name="手机号码" label="手机号码" v-model="dataForm.mobile" placeholder="请输入手机号码"
          clearable clear-trigger="always"
          required :rules="[{ validator: isMobile, message: '请输入正确的手机号码' }]" />

        <van-field name="国籍" label="国籍" v-model="show.nationalityStr" placeholder="请选择国籍"
          readonly clickable is-link @click="$router.push('./nationality')"
          required :rules="[{ required: true, message: '请选择国籍' }]" />

        <van-field name="民族" label="民族" v-model="show.nationStr" placeholder="请选择民族"
          readonly clickable is-link @click="$router.push('./nation')"
          required :rules="[{ required: true, message: '请选择民族' }]" />

        <van-field name="出生日期" label="出生日期" v-model="dataForm.birthday" placeholder="请选择出生日期"
          readonly clickable is-link @click="control.birthday = true"
          required :rules="[{ required: true, message: '请选择出生日期' }]" />
        <van-calendar v-model="control.birthday" :show-confirm="false" color="#1989fa"
          :default-date="new Date(1997, 0, 1)" :min-date="new Date(1945, 0, 1)" :max-date="new Date()"
          @confirm="birthdayCalendar" />

        <van-field name="户籍地址" label="户籍地址" v-model="dataForm.regAddress" placeholder="请输入户籍地址"
          clearable clear-trigger="always"
          type="textarea" autosize />

        <van-field name="现居地址" label="现居地址" v-model="dataForm.nowAddress" placeholder="请输入现居地址"
          clearable clear-trigger="always"
          type="textarea" autosize />

        <van-field name="居住小区" label="居住小区" v-model="show.subarea" placeholder="请选择居住小区"
          readonly clickable is-link @click="control.subarea = true"
          required :rules="[{ required: true, message: '请选择居住小区' }]" />
        <van-popup v-model="control.subarea" position="bottom">
          <van-picker show-toolbar value-key="name" :columns="selectList.subareaList" @confirm="subareaConfirm($event)"
            @cancel="control.subarea = false" />
        </van-popup>

        <van-field name="居住房屋" label="居住房屋" v-model="show.houseName" placeholder="请选择居住房屋"
          readonly clickable is-link @click="control.house = true"
          required :rules="[{ required: true, message: '请选择居住房屋' }]" />
        <van-popup v-model="control.house" round position="bottom">
          <van-cascader v-model="dataForm.houseId" title="居住房屋" :options="selectList.houseList"
            @close="control.house = false" @finish="houseFinish" :field-names="fieldNames" @change="houseConfirm" />
        </van-popup>

        <van-field name="户籍性质" v-model="show.registryTypeStr" placeholder="请选择户籍性质"
          readonly clickable is-link @click="control.registryType = true"
          required :rules="[{ required: true, message: '请选择户籍性质' }]">
          <template #label>
            <div style="display: flex; align-items: center">
              <span>户籍性质</span>
              <van-icon @click.stop="showRegistryType = true" name="question-o" size="18" color="#ccc"
                style="margin-left: 2px" />
            </div>
          </template>
        </van-field>

        <van-popup v-model="showRegistryType"
          :style="{ width: '70%', borderRadius: '10px', background: '#fff', padding: '15px' }" closeable
          close-icon="close">
          <div style="width: 100%; text-align: center; font-size: 16px; line-height: 24px;">户籍性质</div>
          <div v-for="item, key in registryContent" :key="key" style="font-size: 16px;margin-top: 12px;">
            <span style="color: #387FF5">{{ key }}：</span>
            <span>{{ item }}</span>
          </div>
        </van-popup>
        <van-popup v-model="control.registryType" position="bottom">
          <van-picker show-toolbar value-key="label" :columns="selectList.registryTypeList" @confirm="registryType"
            @cancel="control.registryType = false" />
        </van-popup>

        <div class="houseTypeDes" v-if="showHouseTypeDes">
          {{ this.registryContent[show.registryTypeStr] }}
        </div>

        <van-field name="与房主关系" label="与房主关系" v-model="show.relationship" placeholder="请选择与房主关系"
          readonly clickable is-link @click="control.relationship = true"
          required :rules="[{ required: true, message: '请选择与房主关系' }]" />
        <van-popup v-model="control.relationship" position="bottom">
          <van-picker show-toolbar value-key="label" :columns="selectList.relationshipList"
            @confirm="relationshipConfirm($event)" @cancel="control.relationship = false" />
        </van-popup>

        <van-cell name="健康码颜色" title="健康码颜色">
          <template #label>
            <van-radio-group v-model="dataForm.codeColor" direction="horizontal">
              <van-radio name="1">绿码</van-radio>
              <van-radio name="2">黄码</van-radio>
              <van-radio name="3">红码</van-radio>
            </van-radio-group>
          </template>
        </van-cell>

        <van-cell name="上传行程码、健康码图片" title="上传行程码、健康码图片" :border="false"></van-cell>
        <van-cell style="padding-top: 0">
          <template #title>
            <upload-file
              :file-list="fileList1"
              del-btn-show
              upload-btn-show
              @filesUpload="upload1"
              upload-path="files/vacc/return/temporary"
            />
          </template>
        </van-cell>

        <van-cell class="info-title">
          <template #title>
            <div class="blue-box"></div>
            <span class="form-title-view">返程信息</span>
          </template>
        </van-cell>

        <van-cell name="核酸证明持有情况" title="核酸证明持有情况">
          <template #label>
            <van-radio-group v-model="dataForm.report" direction="horizontal">
              <van-radio name="1">24小时内</van-radio>
              <van-radio name="2">48小时内</van-radio>
              <van-radio name="3">否</van-radio>
            </van-radio-group>
          </template>
        </van-cell>

        <van-cell name="上传核酸证明照片" title="上传核酸证明照片" :border="false"></van-cell>
        <van-cell style="padding-top: 0">
          <template #title>
            <upload-file
              :file-list="fileList2"
              del-btn-show
              upload-btn-show
              @filesUpload="upload2"
              upload-path="files/vacc/return/temporary"
            />
          </template>
        </van-cell>

        <van-field name="返程到达时间" label="返程到达时间" v-model="dataForm.backDate" placeholder="请选择返程到达时间"
          readonly clickable is-link @click="control.backDate = true" />
        <van-popup v-model="control.backDate" position="bottom">
          <van-datetime-picker @cancel="control.backDate = false" @confirm="backDateChange" type="date"
            title="选择年月日" :min-date="defaultDate" :max-date="new Date()" />
        </van-popup>

        <van-field name="来源地" label="来源地" v-model="show.source" placeholder="请选择来源地"
          readonly clickable is-link @click="control.source = true"
          required :rules="[{ required: true, message: '请选择来源地' }]" />
        <van-popup v-model="control.source" round position="bottom">
          <van-cascader v-model="dataForm.source" title="来源地" :options="selectList.sourceList"
            @close="control.source = false" @finish="sourceFinish" :field-names="fieldNames" @change="sourceConfirm" />
        </van-popup>

        <van-cell name="您14天还去过哪里" title="您14天还去过哪里" :border="false"></van-cell>
        <van-field v-model="dataForm.stopover" rows="2" style="padding-top: 0"
          autosize type="textarea" placeholder="例：（：xx省 xx市 xx区）" input-align="left"	/>

        <van-cell >
          <van-checkbox shape="square" v-model="dataForm.choise"><span style="color: #f10">*</span>
            本人已如实填写上述内容，若因个人瞒报、谎报造成不良后果，责任由我本人承担。</van-checkbox>
        </van-cell>
      </div>
      <div class="form-btn-view">
        <van-row class="btns">
          <van-col span="24">
            <van-button type="info" size="large" round native-type="submit">保存</van-button>
          </van-col>
        </van-row>
      </div>
    </van-form>
  </div>

</template>

<script>
import UploadFile from '@/components/upload/uploadFile'
import { mapState } from "vuex";
import { getImageStream } from "@/utils/index";
import { formatterDate } from "@/utils/utils";
import { getBirthdayFromIdCard } from "@/utils/utils";
import { getSexFromIdCard } from "@/utils/utils";
import { IdentityCodeValid, isMobile } from "@/utils/validate";
import { getVirtualDict, getHouseList, getAreaList } from "@/utils/common"

var that
export default {
  name: "info",
  components: {
    UploadFile
  },
  data() {
    return {
      rt: '',
      agentType: this.$agentType,
      defaultDate: new Date(2022, 0, 1),
      showCommunity: false,
      fieldNames: {
        text: 'label',
        value: 'value',
        children: 'children',
      },
      registryContent: {
        '人户一致': '居民居住的房子和户籍地址相同。',
        '人在户不在': '常住人口类型之一，没有在本社区落户，例如房东或业主买了本社区的房子，户口未迁过去。',
        '户在人不在': '常住人口类型之一，例如房东或业主落户，但是未入住的。',
        '集体户(人户均在)': '人户均在本村社。',
        '集体户(户在人不在)': '户在本村社，人不在本村社居住。',
        '人户均不在': '流动人口类型之一，例如投资客。',
        '本地租客': '本市户口的租客。',
        '其他人员': '组织关系挂靠，例如党员或退休关系挂靠。'
      },
      showHouseTypeDes: false,
      showRegistryType: false,
      fileList1: [],
      fileList2: [],
      newFileMessages1: [],
      newFileMessages2: [],
      dataForm: {
        id: '',
        orgId: '',
        userName: '',
        sex: '',
        nationality: '59',
        idNumber: '',
        idNumberType: 1,
        mobile: '',
        nation: '2',
        birthday: '',
        company: '',
        regAddress: '',
        nowAddress: '',
        emContact: '',
        emMobile: '',
        type: '',
        houseId: '',
        temporaryHeadImg: "",
        temporaryHeadImgSuffix: "",
        relationship: '',
        registryType: '',
        subarea: '',
        houseId: '',
        codeColor: '1',
        report: '1',
        backDate: '',
        source: '',
        stopover: '',
        choise: '',
        sourceProvince: '',
        sourceCity: '',
        sourceCounty: '',
        depth: 5
      },
      show: {
        community: '',
        idNumberTypeStr: "身份证",
        sex: '',
        nationalityStr: "中国",
        nationStr: "汉族",
        headImg: getImageStream("files/wx/images/content/headImg.png"),//展示头像图
        registryTypeStr: "",
        relationship: '',
        religious: '',
        subarea: '',
        houseName: '',
        source: ''
      },
      control: {
        community: false,
        idNumberType: false,
        birthday: false,
        relationship: false,
        registryType: false,
        sex: false,
        subarea: false,
        house: false,
        backDate: false,
        source: false
      },
      selectList: {
        communityList: [],
        idNumberTypeList: [],
        relationshipList: [],
        subareaList: [],
        houseList: [],
        sourceList: []
      }
    }
  },
  watch: {
    'show.registryTypeStr'(val) {
      if (val) {
        this.showHouseTypeDes = true
      } else {
        this.showHouseTypeDes = false
      }
    }
  },
  mounted () {
    if (this.nationality != 0 && typeof this.nationality == 'object') {
      this.show.nationalityStr = this.nationality.label
      this.dataForm['nationality'] = this.nationality.value
    }
    if (this.nation != 0 && typeof this.nation == 'object') {
      this.show.nationStr = this.nation.label
      this.dataForm['nation'] = this.nation.value
    }
  },
  computed: { ...mapState(['nationality', 'nation']) },
  created() {
    that = this
    this.dataForm.orgId = this.$store.state.returnData.o || this.$orgId
    if (this.$store.state.returnData.d == 4) {
      this.dataForm.depth = 4
      this.showCommunity = true
      this.getCommunityList()
    }
    this.rt = this.$store.state.returnData.rt || 2
    this.dataForm.idNumber = this.$idNumber
    this.getInfoByIdNumber()
    this.dataForm.birthday = getBirthdayFromIdCard(this.dataForm.idNumber)
    this.dataForm.sex = getSexFromIdCard(this.dataForm.idNumber)
    this.show.sex = this.dataForm.sex == 1 ? '男' : '女'
    this.dataForm.backDate = formatterDate(new Date())
    this.init()
  },
  methods: {
    upload1 (files) {
      this.fileList1 = files
      this.newFileMessages1 = files.filter(item => !item.id)
    },
    upload2 (files) {
      this.fileList2 = files
      this.newFileMessages2 = files.filter(item => !item.id)
    },
    getCommunityList () {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/sys/org/communityList`),
        method: 'get',
        params: this.$http.adornParams({
          street: this.$globalData.userInfo.street
        })
      }).then(({ data }) => {
        if (data.code == 0) {
          this.selectList.communityList = data.communityList
        }
      })
    },
    communityConfirm (e) {
      this.$orgId = e.id
      this.show.community = e.name
      this.control.community = false
    },
    IdentityCodeValid(val) {
      return IdentityCodeValid(val)
    },
    isMobile (val) {
      return isMobile(val)
    },
    init() {
      this.selectInfo()
      this.dataForm.orgId = this.$orgId
    },
    getInfoByIdNumber() {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/user/userByIdNumberNotLogin`),
        method: 'get',
        params: this.$http.adornParams({
          idNumber: this.dataForm.idNumber
        }, false)
      }).then(({ data }) => {
        if (data.code == 0) {
          if (data.userInfoList && data.userInfoList.length > 0) this.dataFormInfo(data.userInfoList[0])
        }
      })
    },
    dataFormInfo(info) {
      // 用户基本信息
      that.dataForm.id = info.id || 0
      that.dataForm.userName = info.name || ""
      that.dataForm.mobile = info.mobile || ""
      that.dataForm.sex = info.sex || ""
      that.dataForm.nationality = info.nationality || ""
      that.dataForm.nation = info.nation || ""
      that.dataForm.idNumber = info.idNumber || ""
      that.dataForm.birthday = info.birthday || ""
      that.dataForm.regAddress = info.regAddress || ""
      that.dataForm.nowAddress = info.nowAddress || ""
      that.dataForm.headImg = info.headImg || ""
      that.dataForm.company = info.company || ""
      that.show.sex = info.sex == 1 ? '男' : '女'
      that.show.nationalityStr = info.nationalityStr || "未知"
      that.show.nationStr = info.nationStr || "未知"
      that.show.idNumberTypeStr = info.idNumberTypeStr || "身份证"
      that.dataForm.idNumberType = info.idNumberType || "1"
      if (null != info.headImg) {
        that.show.headImg = info.headImg ? getImageStream(info.headImg) : getImageStream("files/wx/images/content/headImg.png")
      }
      that.dataForm.registryType = info.registryType + '' || ""
      that.dataForm.emMobile = info.emMobile || ""
      that.dataForm.emContact = info.emContact || ""
      that.show.registryTypeStr = info.registryTypeStr || ""
    },
    selectInfo() {
      this.userSelect()
      this.houseSelect()
    },
    userSelect() {
      //户籍性质
      this.$http({
        url: this.$http.adornUrl(`/wxapp/sys/dict/virtual/registryType`),
        method: 'get',
        params: this.$http.adornParams({})
      }).then(({ data }) => {
        if (data.code == 0) {
          data.registryTypes.map((type) => {
            type.value = type.value + ""
          })
          that.selectList.registryTypeList = data.registryTypes
        }
      })
      //身份证类型
      getVirtualDict("idNumberType", function (virtualDictList) {
        that.selectList.idNumberTypeList = virtualDictList
      })
      getAreaList(1, 0, (res) => {
        that.selectList.sourceList = res
      })
    },
    houseSelect() {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/sys/dict/virtual/relationships`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        if (data.code == 0) {
          data.relationships.map((r) => {
            r.value = r.value + ""
          })
          that.selectList.relationshipList = data.relationships
        }
      })
      this.$http({
        url: this.$http.adornUrl(`/wxapp/sys/org/subAreaList`),
        method: 'get',
        params: this.$http.adornParams({
          communityId: this.$orgId
        })
      }).then(({ data }) => {
        if (data.code == 0) {
          this.selectList.subareaList = data.subAreaList
        }
      })
    },
    cardIdIdentified(e) {
      let formdata = new FormData();
      formdata.append("file", e.file)
      this.$http({
        url: this.$http.adornUrl('/wxapp/file/cardIdIdentified'),
        method: 'post',
        header: {
          wxAppId: 'wxfa4ce77dc91e7327'
        },
        data: formdata
      }).then(({ data }) => {
        if (data.code == 0) {
          this.dataForm.userName = data.message.userName
          this.dataForm.idNumberType = 1
          this.dataForm.idNumber = data.message.id
          this.dataForm.regAddress = data.message.addr
          this.dataForm.birthday = data.message.birthday
          this.dataForm.sex = data.message.gender == "男" ? 1 : 2
          this.dataForm.nation = data.message.nation
          this.show.nationStr = data.message.nationality
          this.show.idNumberTypeStr = '身份证'
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    idNumberType(e) {
      this.dataForm.idNumberType = parseInt(e.value)
      this.show.idNumberTypeStr = e.label
      this.control.idNumberType = false
    },
    birthdayCalendar(e) {
      let date = formatterDate(e)
      this.control.birthday = false
      this.dataForm.birthday = date
    },
    relationshipConfirm(e) {
      this.dataForm.relationship = e.value
      this.show.relationship = e.label
      this.control.relationship = false
    },
    subareaConfirm(e) {
      this.dataForm.subarea = e.id
      this.show.subarea = e.name
      this.control.subarea = false
      getHouseList(e.id, 1, (list) => {
        that.selectList.houseList = list.map(item => {
          return {
            ...item,
            children: item.parameters.divideHouse == 0 && item.parameters.divideUnit == 0 ? null : []
          }
        })
      })
    },
    houseConfirm(e) {
      let val = e.selectedOptions[e.selectedOptions.length - 1]
      getHouseList(val.value, val.depth, (list) => {
        e.selectedOptions.forEach(item => {
          if (item.value == val.value) {
            item.children = list.length > 0 ? list.map(item => {
              return {
                ...item,
                children: item.depth == 4 ? null : []
              }
            }) : null
          }
        })
      })
    },
    houseFinish({ selectedOptions }) {
      this.control.house = false
      this.show.houseName = selectedOptions.map(item => item.label).join('-')
      if (selectedOptions[selectedOptions.length - 1].depth == 2) {
        this.dataForm.houseId = selectedOptions[selectedOptions.length - 1].parameter
      } else if (selectedOptions[selectedOptions.length - 1].depth == 4) {
        this.dataForm.houseId = selectedOptions[selectedOptions.length - 1].value
      }
    },
    sourceConfirm(e) {
      let val = e.selectedOptions[e.selectedOptions.length - 1]
      if (val.depth < 3) {
        getAreaList(val.value, val.depth, (list) => {
          e.selectedOptions.forEach(item => {
            if (item.value == val.value) {
              item.children = list.length > 0 ? list.map(item => {
                return {
                  ...item,
                  children: item.depth == 3 ? null : []
                }
              }) : null
            }
          })
        })
      }
    },
    sourceFinish({ selectedOptions }) {
      if (selectedOptions.length == 3) {
        this.dataForm.sourceProvince = selectedOptions[0].value
        this.dataForm.sourceCity = selectedOptions[1].value
        this.dataForm.sourceCounty = selectedOptions[2].value
        this.show.source = selectedOptions.map(item => item.label).join('')
        this.dataForm.source = selectedOptions.map(item => item.label).join('')
        this.control.source = false
      }
    },
    registryType(e) {
      this.dataForm.registryType = parseInt(e.value)
      this.show.registryTypeStr = e.label
      this.control.registryType = false
    },
    sexConfirm(value) {
      this.dataForm.sex = value.value
      this.show.sex = value.label
      this.control.sex = false
    },
    backDateChange(value) {
      this.dataForm.backDate = formatterDate(value)
      this.control.backDate = false
    },
    //提交
    onSubmit(e) {
      this.$refs.form.validate().then(() => {
        if (this.newFileMessages1.length == 0) {
          return this.$toast.fail('请上传行程码、健康码图片')
        }
        if (this.newFileMessages2.length == 0) {
          return this.$toast.fail('请上传核酸证明照片')
        }
        if (!this.dataForm.choise) {
          return this.$toast.fail('请同意以上内容')
        }
        this.$dialog.confirm({
          message: '确认提交？',
        }).then(() => {
          this.$httpApp({
            url: this.$httpApp.adornUrl(`/wxapp/vacc/return/save`),
            method: 'post',
            data: this.$httpApp.adornData({
              'orgId': this.$orgId,
              newFileMessages: this.newFileMessages1,
              fileMessages: this.newFileMessages1,
              newFileMessages2: this.newFileMessages2,
              fileMessages2: this.newFileMessages2,
              imgList: this.newFileMessages1.map(item => {return item.relativePath.replace(/\\/g, "/")}),
              reportImg: this.newFileMessages2.map(item => {return item.relativePath.replace(/\\/g, "/")})[0],
              'userName': that.dataForm.userName,
              'idNumber': that.dataForm.idNumber,
              'idNumberType': that.dataForm.idNumberType,
              'mobile': that.dataForm.mobile,
              'regAddress': that.dataForm.regAddress,
              'registryType': that.dataForm.registryType,
              'subarea': that.dataForm.subarea,
              'sex': that.dataForm.sex,
              'nationality': that.dataForm.nationality,
              'nation': that.dataForm.nation,
              'birthday': that.dataForm.birthday,
              'nowAddress': that.dataForm.nowAddress,
              'company': that.dataForm.company,
              'houseId': that.dataForm.houseId,
              'fullName': that.show.houseName,
              'relationship': that.dataForm.relationship,
              'codeColor': this.dataForm.codeColor,
              'report': this.dataForm.report,
              'source': this.dataForm.source,
              'stopover': this.dataForm.stopover,
              'backDate': this.dataForm.backDate,
              sourceProvince: this.dataForm.sourceProvince,
              sourceCity: this.dataForm.sourceCity,
              sourceCounty: this.dataForm.sourceCounty,
              returnType: 2,
              community: this.$orgId,
              choise: this.dataForm.choise,
              returnType: 2,
              depth: this.dataForm.depth,
              destination: this.show.subarea + this.show.houseName,
              communityName: this.$globalData.userInfo.communityName || this.show.community,
              orgName: this.$globalData.userInfo.streetName + this.$globalData.userInfo.communityName || ''
            })
          }).then(({ data }) => {
            if (data.code == 0) {
              this.$toast.success({
                message: '保存成功',
                duration: 3000,
                onOpened: () => {
                  setTimeout(() => {
                    this.$router.go(-1)
                  }, 1500)
                }
              })
            } else {
              that.$toast.fail(data.msg)
            }
          })
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.required {
  &::before {
    content: '*';
    color: red;
    position: absolute;
    left: 10px;
  }
}

.houseTypeDes {
  color: red;
  font-size: 24px;
  line-height: 26px;
  padding: 10px;
}

::v-deep .van-uploader,
::v-deep .van-uploader__input-wrapper {
  width: 100%;
}

.headImg {
  width: 104px;
  height: 104px;
  border-radius: 50%;
}

.van-cell {
  align-items: center;
}

::v-deep .van-field__control--left {
  text-align: left !important;
}
.info .van-radio-group {
  float: left;
}
</style>
